import React, { useContext } from 'react'

import { ButtonV2, Icon, Text } from '@vividseats/vivid-ui-kit'

import MenuItemLink from '@/components/layouts/main-layout/components/header/components/main-menu/components/shared/menu-item-link'
import { getMenuAriaLabel } from '@/components/layouts/main-layout/components/header/components/main-menu/utils'
import { CURRENCY_DISCLAIMER_LINE1, CURRENCY_DISCLAIMER_LINE2 } from '@/constants'
import InternationalizationContext from '@/context/internationalization'
import { CountryFlag } from '@/design-system/custom-icons'

import styles from '../menu-item/styles.module.scss'

import CurrencyPicker from './components/currency-picker/component'

interface Props {
    activeSubmenuPath: number[]
    index: number
    onBackButtonClick: () => void
    onCloseButtonClick: () => void
    onItemClick: (index: number) => void
}

const HeaderCurrencyPicker: React.FC<Props> = ({
    activeSubmenuPath,
    index,
    onBackButtonClick,
    onCloseButtonClick,
    onItemClick,
}) => {
    const {
        selectors: { flag, selectedCurrency },
    } = useContext(InternationalizationContext)

    const currencyChildMenuHeaderElement = (
        <ButtonV2 className={styles.backButton} data-testid="back-button" importance="text" onClick={onBackButtonClick}>
            <Icon className={styles.backIcon} type="back" size="md" />
            <div className={styles.menuHeaderText}>Currencies</div>
        </ButtonV2>
    )

    const currencyChildMenuFooterElement = (
        <div className={styles.footerDivider} data-testid="disclaimer-wrapper">
            <Text altFont weight="regular" className={styles.footerText}>
                {CURRENCY_DISCLAIMER_LINE1} {CURRENCY_DISCLAIMER_LINE2}
            </Text>
        </div>
    )

    return (
        <li data-testid="header-currency-picker" className={styles.menuItem}>
            <MenuItemLink
                data-testid="header-selected-title"
                block={true}
                href=""
                level={2}
                withSublinks={true}
                isDesktop={false}
                label={getMenuAriaLabel(selectedCurrency)}
                onClick={(e) => {
                    e.preventDefault()
                    onItemClick(index)
                }}
            >
                <CountryFlag flag={flag} />
                {selectedCurrency}
            </MenuItemLink>
            {activeSubmenuPath[0] === 4 && (
                <div className={styles.sublinks}>
                    <CurrencyPicker
                        active
                        footerElement={currencyChildMenuFooterElement}
                        headerElement={currencyChildMenuHeaderElement}
                        title={'Choose your display currency'}
                        onCloseButtonClick={onCloseButtonClick}
                    />
                </div>
            )}
        </li>
    )
}

export default HeaderCurrencyPicker
