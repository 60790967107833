import type { ChangeEvent, KeyboardEventHandler } from 'react'
import React, { useCallback, useRef, useEffect } from 'react'

import { Icon, Button, Box } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import isNil from 'lodash/fp/isNil'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { PATHS } from '@/constants'
import { trackSearch } from '@/utils/analytics/segment'

import Input from './components/input'
import styles from './styles.module.scss'

interface SearchFormProps {
    placeholder: string
    value: string
    onChange: (value: string) => void
    onKeyPress?: (value: string) => void
    onKeyDownFocus?: KeyboardEventHandler<Element>
    onSubmit?: (value: string) => void
    onCancel?: () => void
    onFocus?: () => void
    onReset?: () => void
    onBlur?: () => void
    onEnter?: () => void
    isActive?: boolean
    isCenteredInHero?: boolean
    isFullWidth?: boolean
    shouldShowAnimatedPlaceholder?: boolean
    shouldGrow?: boolean
    cursorPosition?: number
    handleSetCursorPosition?: (val: number) => void
    handleGoToSelectedItem?: () => void
}

const SearchForm: React.FC<SearchFormProps> = ({
    placeholder,
    value,
    onChange,
    onKeyDownFocus,
    onReset,
    onFocus,
    onCancel,
    onBlur,
    onEnter,
    isActive,
    isCenteredInHero,
    isFullWidth,
    shouldShowAnimatedPlaceholder = false,
    shouldGrow = true,
    cursorPosition,
    handleSetCursorPosition,
    handleGoToSelectedItem,
}) => {
    // Note the API call itself is debounced in the SuggestionsContext based on this value
    const updateQuery = useCallback((nextQueryValue: string) => onChange && onChange(nextQueryValue), [onChange])
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (isActive && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isActive])

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        updateQuery(value)
    }

    const isEmpty = !(value.trim().length > 0)
    const inputStyles = classNames(styles.input, {
        [styles.hasValue]: !isEmpty,
        [styles.isCenteredInHero]: isCenteredInHero,
        [styles.isFullWidth]: isFullWidth,
        [styles.animatedPlaceholder]: shouldShowAnimatedPlaceholder,
    })

    const pseudoPlaceholder = classNames(styles.pseudoPlaceholder, {
        [styles.isNotCentered]: !isCenteredInHero,
        [styles.isCentered]: isCenteredInHero,
    })

    const searchIconStyles = classNames(styles.icon, styles.searchIcon, {
        [styles.isPrimary]: isActive,
        [styles.isHidden]: !isActive && !value && isCenteredInHero,
    })

    const containerStyles = classNames(styles.container, {
        [styles.isCenteredInHero]: isCenteredInHero,
    })

    const searchLabelStyles = classNames(styles.searchLabel, {
        [styles.isHidden]: isActive,
    })

    const handleKeyDown = (event: React.KeyboardEvent) => {
        const { tagName, value } = event.target as HTMLInputElement
        const searchTerm = value?.trim()

        if (event.key === 'Escape' && onCancel) {
            onCancel()
        }

        if (event.key === 'Enter') {
            if (cursorPosition && handleGoToSelectedItem) {
                handleGoToSelectedItem()
            } else if (tagName === 'INPUT') {
                window.location.assign(`${PATHS.SEARCH}?searchTerm=${encodeURIComponent(searchTerm)}`)
                onEnter?.()
                trackSearch(searchTerm)
            }
        }

        if (handleSetCursorPosition && !isNil(cursorPosition)) {
            if (event.key === 'ArrowDown') {
                handleSetCursorPosition && handleSetCursorPosition(cursorPosition + 1)
            }
            if (event.key === 'ArrowUp') {
                handleSetCursorPosition && handleSetCursorPosition(cursorPosition - 1)
            }
        }

        if (onKeyDownFocus) {
            onKeyDownFocus(event)
        }
    }

    const animatedPlaceholder = (
        <Box display="flex" data-testid="animated-placeholder" paddingLeft={3}>
            <span className={pseudoPlaceholder}>Search</span>
            <SwitchTransition>
                <CSSTransition
                    classNames={{
                        enterActive: styles.fadeEnterActive,
                        enterDone: styles.fadeEnterDone,
                        exitActive: styles.fadeExitActive,
                        exitDone: styles.fadeExitDone,
                        exit: styles.fadeExit,
                        enter: styles.fadeEnter,
                    }}
                    timeout={300}
                    key={placeholder}
                >
                    <div className={styles.animatedPlaceholder} data-testid="animated-placeholder-text">
                        {placeholder}
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </Box>
    )

    return (
        <div className={containerStyles} data-testid="search-field-container">
            <div className={styles.innerContainer}>
                <Icon className={searchIconStyles} size="md" type="search" />
                {!value && isCenteredInHero && (
                    <label className={searchLabelStyles} data-testid="search-label">
                        <Icon className={classNames(styles.icon, styles.searchLabelIcon)} size="md" type="search" />
                        {shouldShowAnimatedPlaceholder ? animatedPlaceholder : placeholder}
                    </label>
                )}
                {shouldShowAnimatedPlaceholder && !isCenteredInHero && (
                    <label className={styles.notCenteredAnimatedSearchLabel} data-testid="search-label">
                        {animatedPlaceholder}
                    </label>
                )}
                <Input
                    data-testid="search-field"
                    className={inputStyles}
                    value={value}
                    onChange={onChangeHandler}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    sizing={isActive && shouldGrow ? 'large' : 'small'}
                    onKeyDown={handleKeyDown}
                    isCenteredInHero={isCenteredInHero}
                    isFullWidth={isFullWidth}
                    ref={inputRef}
                />
                {!isEmpty && (
                    <Button
                        importance="text"
                        data-testid="close-button"
                        className={styles.closeButton}
                        onClick={onReset}
                        tabIndex={-1}
                    >
                        <Icon className={styles.icon} size="md" type="close-circle" />
                    </Button>
                )}
            </div>
            {isActive && !isCenteredInHero && !isFullWidth && (
                <Button
                    data-testid="cancel-button"
                    className={styles.cancelButton}
                    size="large"
                    importance="text"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            )}
        </div>
    )
}

export default SearchForm
