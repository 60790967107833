import { LEGAL_LINKS, PATHS, URLS } from '@/constants'

const { ENVIRONMENT } = process.env

export default {
    top: [
        {
            title: 'Connect',
            items: [
                { label: 'Contact Us', to: PATHS.CONTACT_US },
                { label: 'Event News', to: PATHS.BLOG },
                { label: 'Facebook', to: URLS.VIVIDSEATS_FACEBOOK },
                { label: 'Instagram', to: URLS.VIVIDSEATS_INSTAGRAM },
                { label: 'Twitter', to: URLS.VIVIDSEATS_TWITTER },
            ],
        },
        {
            title: 'Our Company',
            items: [
                { label: 'About Us', to: URLS.CORP },
                { label: 'Buyer Guarantee', to: PATHS.BUYER_GUARANTEE },
                { label: 'Careers', to: URLS.CAREERS },
                { label: 'Press', to: `${ENVIRONMENT === 'stage' ? URLS.CORP_STAGE : URLS.CORP}${PATHS.PRESS}` },
                { label: 'Investors', to: URLS.INVESTORS },
                { label: 'Reviews', to: PATHS.REVIEWS },
                { label: 'Reliability', to: PATHS.RELIABILITY },
            ],
        },
        {
            title: 'Our Services',
            items: [
                { label: 'Affiliate Program', to: PATHS.AFFILIATES },
                { label: 'Partners', to: URLS.PARTNERS },
                { label: 'Sell Tickets', to: PATHS.SELL_TICKETS },
                { label: 'Student Discount', to: PATHS.STUDENT_DISCOUNT },
            ],
        },
        {
            title: 'Shop',
            items: [
                { label: 'Gift Cards', to: PATHS.GIFT_CARDS },
                { label: 'Rewards', to: PATHS.REWARDS },
                { label: 'Vivid Seats App', to: URLS.VIVIDSEATS_MOBILE_APP },
            ],
        },
    ],

    bottom: [{ label: 'Site Map', to: PATHS.SITEMAP }, ...LEGAL_LINKS],
}
