import { createSelector } from 'reselect'

import { getBreadcrumbsFromCategory } from '@/components/shared/breadcrumbs'
import { MAX_PRODUCTIONS_DATE_SWITCHER } from '@/constants'
import type { ProductionItem } from '@/context/utils/jsonld'
import { getProductionJson } from '@/context/utils/jsonld'
import type { JsonLdItem, Performer, ProductionDetail } from '@/types/app-types'
import { CategoryName } from '@/types/enums'
import { getDateTime, getProductionPageDateTime } from '@/utils/dates'
import { getPerformerUrl } from '@/utils/production'

import type {
    DateSwitcherInfo,
    DateSwitcherSheetInfo,
    EventInfo,
    EventLineup,
    EventLineupPerformer,
    HeroData,
    ProductionDetailsState,
    ProductionVenueInfo,
} from './types'
import { getExternalHeroImage, getHeroImage, isParkingPerformer } from './utils'

export const jsonLdDataSelector = createSelector(
    ({ data }: ProductionDetailsState) => data,
    (productionDetails: ProductionDetail | undefined): JsonLdItem => {
        return productionDetails
            ? getProductionJson(productionDetails as ProductionItem)
            : { '@context': 'http://schema.org' }
    },
)

export const heroDataSelector = createSelector(
    ({ data }: ProductionDetailsState) => data,
    (productionDetails: ProductionDetail | undefined): HeroData | undefined => {
        if (!productionDetails) {
            return undefined
        }

        const { name: title, venue, singleProduction, formattedDate, performers, assets } = productionDetails
        const location = `${venue.name} ${String.fromCharCode(8226)} ${venue.city}${
            !!venue.state ? `, ${venue.state}` : ''
        }${venue.countryCode.toLowerCase() !== 'us' ? `, ${venue.countryCode}` : ''}`
        const subtitleTop = { icon: 'location', content: location } as const

        const shouldShowDate = singleProduction || isParkingPerformer(performers)
        // TODO: BWCT-326
        const subtitleBottom = shouldShowDate
            ? ({ icon: 'calendar', content: getProductionPageDateTime(formattedDate) } as const)
            : undefined

        const performerId = performers[0].id
        const href = getPerformerUrl(performers[0])
        const mobileImage: string = getHeroImage(performerId, assets, 'mobile')
        const desktopImage: string = getHeroImage(performerId, assets, 'desktop')
        const externalImage: string | undefined = getExternalHeroImage(performerId, assets)

        return {
            title,
            subtitleTop,
            subtitleBottom,
            mobileImage,
            desktopImage,
            externalImage,
            href,
        }
    },
)

export const eventInfoSelector = createSelector(
    ({ data }: ProductionDetailsState) => data,
    (productionDetails: ProductionDetail | undefined): EventInfo | undefined => {
        if (!productionDetails) {
            return undefined
        }

        const { id, performers, venue } = productionDetails
        const mainPerformer: Performer = performers[0]

        const mainPerformerName: string = mainPerformer.name
        const venueName: string = venue.name
        const venueURL = venue.webPath ?? ''

        const countryCode = venue.countryCode?.toLowerCase() !== 'us' ? `, ${venue.countryCode}` : ''
        const venueState = !!venue.state ? `, ${venue.state}` : ''
        const venueCity = venue.city ?? ''
        const locationName = `${venueCity}${venueState}${countryCode}`
        const locationURL = venue.regionId
            ? `/region/${venue.countryCode}/${venue.state}/${venue.city}-tickets/${venue.regionId}`.toLowerCase()
            : ''

        const idLicenses: number | undefined = venue.state.toLowerCase() === 'ny' ? id : undefined
        // TODO: Set 'isExternalLink' flag to false once category/subcategory pages are implemented in Next.js
        const breadcrumbs = getBreadcrumbsFromCategory(mainPerformer.category, { isExternalLink: true })

        return {
            mainPerformerName,
            venueName,
            venueURL,
            locationName,
            locationURL,
            idLicenses,
            breadcrumbs,
        }
    },
)

export const eventLineupSelector = createSelector(
    ({ data }: ProductionDetailsState) => data,
    (productionDetails: ProductionDetail | undefined): EventLineup | undefined => {
        if (!productionDetails) {
            return undefined
        }

        const { performers, assets } = productionDetails

        const categoryName: string = performers[0].category.name
        const eventLineupPerformers: EventLineupPerformer[] = performers.map((performer: Performer) => {
            const { id: performerId, name } = performer

            // If performer has a concert category type and is not a parking performer use webPath
            // Otherwise use organicUrl
            const url = getPerformerUrl(performer)

            return {
                id: performerId,
                name,
                url,
                image: getHeroImage(performerId, assets, 'mobile'),
            }
        })

        return {
            title: categoryName.toLowerCase() === CategoryName.Sports.toLowerCase() ? 'Teams' : 'Lineup',
            performers: eventLineupPerformers,
        }
    },
)

export const productionVenueInfoSelector = createSelector(
    ({ data }: ProductionDetailsState) => data,
    (productionDetails: ProductionDetail | undefined): ProductionVenueInfo | undefined => {
        if (!productionDetails) {
            return undefined
        }

        const { id: productionId, name: productionName, formattedDate, venue } = productionDetails

        const productionDateTime: string = getDateTime(formattedDate)
        const venueAddress = `${venue.address1}${!!venue.address2 ? ` ${venue.address2}` : ''}, ${venue.city}, ${
            !!venue.state ? `${venue.state}` : ''
        } ${venue.postalCode}${venue.countryCode.toLowerCase() !== 'us' ? `, ${venue.countryCode}` : ''}`

        return {
            productionId,
            productionName,
            productionDateTime,
            venueName: venue.name,
            venueCity: venue.city,
            venueState: venue.state,
            venueAddress,
            countryCode: venue.countryCode,
        }
    },
)

export const dateSwitcherInfoSelector = createSelector(
    ({ data }: ProductionDetailsState) => data,
    (productionDetails: ProductionDetail | undefined): DateSwitcherInfo | undefined => {
        if (!productionDetails) {
            return undefined
        }

        const { id: productionId, productionsCount, performers, formattedDate } = productionDetails

        const maxProductionsCount: number = Math.min(productionsCount, MAX_PRODUCTIONS_DATE_SWITCHER)
        const captionType =
            performers[0].category.name.toLowerCase() === CategoryName.Sports.toLowerCase() ? 'Games' : 'Shows'
        const caption = `1 of ${maxProductionsCount} ${captionType}`

        return {
            label: getDateTime(formattedDate),
            caption,
            productionId,
            productionsCount,
        }
    },
)

export const dateSwitcherSheetInfoSelector = createSelector(
    [
        ({ data }: ProductionDetailsState) => data,
        (state: ProductionDetailsState | undefined, currency: string) => currency,
    ],
    (productionDetails: ProductionDetail | undefined, currency: string): DateSwitcherSheetInfo | undefined => {
        if (!productionDetails) {
            return undefined
        }

        const { performers, venue, productionsCount } = productionDetails

        const categoryName: string = performers[0].category.name

        const subtitle: string =
            categoryName.toLowerCase() === CategoryName.Sports.toLowerCase() && performers[1]
                ? `${performers[1].name} at ${performers[0].name}`
                : `${performers[0].name} at ${venue.name}`

        const queryParams =
            categoryName.toLowerCase() === CategoryName.Sports.toLowerCase() && performers[1]
                ? {
                      performerId: performers[0].id,
                      opponentId: performers[1].id,
                      venueId: venue.id,
                      currency,
                  }
                : { performerId: performers[0].id, venueId: venue.id, currency }

        const maxProductionsCount: number = Math.min(productionsCount, MAX_PRODUCTIONS_DATE_SWITCHER)

        return {
            title: 'Change Event',
            subtitle,
            queryParams,
            productionsCount: maxProductionsCount,
        }
    },
)
