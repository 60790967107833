import type { $FC } from 'react'
import React, { createContext, useState } from 'react'

import useScrollPercentage, { TRANSITION_PERCENTAGE_STEP } from '@/hooks/use-scroll-percentage'

interface State {
    transparentHeaderClassName: string
    scrollPercentage?: number
    isTransparent?: boolean
    listenForScroll?: boolean
    forceOpaque: boolean
    isHeaderSearchbarFocused: boolean
}

interface Dispatch {
    setForceOpaque: (value: boolean) => void
    handleSearchbarFocus: (value: boolean) => void
}

interface InitialProps {
    isTransparent?: boolean
    listenForScroll?: boolean
}

const defaultState: State = {
    transparentHeaderClassName: 'transparentHeader0',
    scrollPercentage: 0,
    isTransparent: false,
    listenForScroll: false,
    forceOpaque: false,
    isHeaderSearchbarFocused: false,
}

const defaultDispatch: Dispatch = {
    setForceOpaque: () => null,
    handleSearchbarFocus: () => null,
}

const headerStyleContext = createContext({ state: defaultState, dispatch: defaultDispatch })
const { Provider } = headerStyleContext

export interface HeaderStyleProps {
    readonly initialProps?: InitialProps
}

/**
 * Handles centralizing header styling colors calculated from the useScrollPercentage() hook
 * @param isTransparent flag will enable/disable this color changing effect
 * @param listenForScroll flag will enable/disable listening for scroll
 */
export const HeaderStyleProvider: $FC<HeaderStyleProps> = ({
    children,
    initialProps = { isTransparent: false, listenForScroll: false, forceOpaque: false },
}) => {
    const [forceOpaque, setForceOpaque] = useState<boolean>(false)
    const [isHeaderSearchbarFocused, setIsHeaderSearchbarFocused] = useState(false)

    const transitionPercentage = initialProps.isTransparent ? TRANSITION_PERCENTAGE_STEP : 1
    const { scrollPercentage } = useScrollPercentage(initialProps.listenForScroll, transitionPercentage)

    const handleSearchbarFocus = (isFocused: boolean) => {
        setIsHeaderSearchbarFocused(isFocused)
        setForceOpaque(isFocused)
    }

    return (
        <Provider
            value={{
                state: {
                    transparentHeaderClassName: `transparentHeader${scrollPercentage}`,
                    forceOpaque,
                    scrollPercentage,
                    isHeaderSearchbarFocused,
                    ...initialProps,
                },
                dispatch: {
                    setForceOpaque,
                    handleSearchbarFocus,
                },
            }}
        >
            {children}
        </Provider>
    )
}

export default headerStyleContext
