import React, { useEffect } from 'react'

import { Typography } from '@mui/material'
import { Link, Span, Box } from '@vividseats/vivid-ui-kit'
import NextLink from 'next/link'

import CopyrightText from '@/components/shared/copyright-text'
import { ACCESSIBILITY_IDS, LEGAL_LINKS, PATHS } from '@/constants'
import type { LabelLink } from '@/types/app-types'
import { useDesktopMediaQuery, useTabletAndBelowMediaQuery } from '@/utils/responsive'

import styles from './styles.module.scss'

/**
 * The `to` property of the link incase the labels change.
 * We need to be specific because we don't want the `Privacy Preferences` to open in a new tab.
 */
const OPEN_IN_NEW_TAB: readonly string[] = [PATHS.PRIVACY_POLICY, PATHS.DO_NOT_SELL] as const

type Props = {
    renderOnDesktop?: boolean
    renderOnMobileAndTablet?: boolean
    open?: boolean
}

const ProductionFooter: React.FC<Props> = ({ renderOnDesktop, renderOnMobileAndTablet, open }) => {
    const footerLinks = LEGAL_LINKS.filter((link) => link.showInProductionFooter)
    const isDesktop = useDesktopMediaQuery()
    const isMobileOrTablet = useTabletAndBelowMediaQuery()

    useEffect(() => {
        // to kick off the privacy preferences link in the footer powered by trustarc
        window.truste?.eu?.icon?.initialize()
    }, [open, isDesktop, isMobileOrTablet])

    if (!open) return <></>
    if (!renderOnDesktop && isDesktop) return <></>
    if (!renderOnMobileAndTablet && isMobileOrTablet) return <></>

    return (
        <footer id={ACCESSIBILITY_IDS.FOOTER} className={styles.footer}>
            <Box
                className={styles.footerContainer}
                desktopDisplay={'block'}
                mobileDisplay={'block'}
                tabletDisplay={'block'}
            >
                <CopyrightText className={styles.copyrightText} small />
                <br />
                <div className={styles.legalLinks}>
                    {footerLinks.map((link, i) => {
                        const { id, label, to } = link as LabelLink
                        return to ? (
                            <Link
                                LinkComponent={NextLink}
                                className={styles.link}
                                key={`${i}-${label}`}
                                href={to || ''}
                                isExternal
                                target={OPEN_IN_NEW_TAB.includes(to) ? '_blank' : undefined}
                                legacyBehavior
                            >
                                <Typography variant="footnote-regular">
                                    <Span id={id} className={styles.navLabel}>
                                        {label}
                                    </Span>
                                </Typography>
                            </Link>
                        ) : (
                            <Typography variant="footnote-regular" component="label" key={`${i}-${label}`}>
                                {label}
                            </Typography>
                        )
                    })}
                </div>
            </Box>
        </footer>
    )
}

export default ProductionFooter
