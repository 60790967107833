import type { FC, RefObject } from 'react'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import type { WithOptimizelyProps } from '@optimizely/react-sdk'
import { withOptimizely } from '@optimizely/react-sdk'
import { backdropContext, Button, Icon } from '@vividseats/vivid-ui-kit'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'

import SearchForm from '@/components/shared/search-form'
import { STYLES } from '@/constants'
import headerStyleContext from '@/context/header'
import SuggestionsContext from '@/context/suggestions'
import { AnalyticsIds } from '@/context/utils/analytics'
import type { Mutable } from '@/design-system/utils/types/Mutable'
import useOptimizelyUser from '@/hooks/use-optimizely-user'
import { useAnimatedSearchbarPlaceholderFeature } from '@/optimizely/features/animated-search-bar-placeholder'
import type { Production } from '@/types/app-types'
import { trackSearchEvent, trackViewProductionList } from '@/utils/analytics'

import FocusSearchSuggestions from './components/focus-search-suggestions'
import SearchSuggestions from './components/search-suggestions'
import styles from './styles.module.scss'
import { removeSearchTermFromStorage, setSearchTermInStorage } from './utils'

type Timeout = NodeJS.Timeout
type TimeoutRef = Mutable<RefObject<Timeout>>

type SearchBarProps = WithOptimizelyProps & {
    isCenteredInHero?: boolean
    isFullWidth?: boolean
    handleSearchbarFocus?: (val: boolean) => void
}

export const PLACEHOLDER_CHANGE_INTERVAL_MS = 3500
export const placeholderItems = ['Beyoncé', 'Houston Rodeo', 'Madonna', 'Hamilton', 'March Madness']

const SearchBar: FC<SearchBarProps> = ({ isCenteredInHero, isFullWidth, optimizely, handleSearchbarFocus }) => {
    const [previousProductions, setPreviousProductions] = useState<Production[]>([])
    const [lastQuery, setLastQuery] = useState<string>('')
    const [isSearchFormActive, setIsSearchFormActive] = useState(false)
    const [isIconOnly, setIsIconOnly] = useState(true)
    const [placeholderIndex, setPlaceholderIndex] = useState(0)

    const placeholderTimeout = useRef<Timeout>(null)
    const searchContainerRef = useRef<HTMLDivElement>(null)

    const { pathname, events: routerEvents, query: routerQuery } = useRouter()
    /**
     * FIXME: this works around a bug in `useWindowSize` where it returns desktop on initial render.
     * See https://vividseats.atlassian.net/browse/BW-2685
     * */
    const isDesktop = typeof window !== 'undefined' && window.innerWidth >= STYLES.breakpoint.desktop
    const isMobile = typeof window !== 'undefined' && window.innerWidth <= STYLES.breakpoint.tablet - 1
    const isTablet = typeof window !== 'undefined' && !isMobile && !isDesktop

    const [isAnimatedSearchbarPlaceholderEnabled] = useAnimatedSearchbarPlaceholderFeature()
    const lockScroll = !isDesktop

    const isHome = pathname === '/home'
    const isSearchPage = pathname === '/search'
    const shouldPersistSearchTerm: boolean = useMemo(
        () =>
            isHome ||
            isSearchPage ||
            pathname.startsWith('/sports') ||
            pathname.startsWith('/theater') ||
            pathname.startsWith('/concerts') ||
            pathname.includes('/performer') ||
            pathname.includes('/venue'),
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [pathname],
    )

    const {
        state: { query, fetching, cursorPosition },
        selectors: { performers, productionListRows, productions, venues, hasResults },
        dispatch: { setQuery, setSearchTerm, handleSetCursorPosition, handleGoToSelectedItem },
    } = useContext(SuggestionsContext)

    const {
        state: { toggled: isBackdropOpen },
        dispatch: { setToggled },
    } = useContext(backdropContext)

    const {
        state: { transparentHeaderClassName, isTransparent },
    } = useContext(headerStyleContext)

    const shouldStartAnimatingPlaceholder = useMemo(() => {
        return !isSearchFormActive && !query && isAnimatedSearchbarPlaceholderEnabled
    }, [isSearchFormActive, query, isAnimatedSearchbarPlaceholderEnabled])

    // Update searchbar_user attribute
    useOptimizelyUser(optimizely, (attributes) => {
        if (isSearchFormActive) attributes.searchbar_user = true
    })

    // close searchbar on path change
    useEffect(() => {
        routerEvents.on('routeChangeStart', onRouteChange)
        return () => routerEvents.off('routeChangeStart', onRouteChange)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    // on mobile, the search bar is only an icon
    // on mobile, the home page should never show only icon
    useEffect(() => {
        if (isMobile && isHome) return setIsIconOnly(false)
        setIsIconOnly((isHome && isTablet) || (!isHome && !isDesktop))
    }, [isTablet, isHome, isDesktop, isMobile])

    // Remove active state and blur input when backdrop is toggled off
    useEffect(() => {
        if (!isBackdropOpen && isSearchFormActive && isDesktop) {
            setIsSearchFormActive(false)
            searchContainerRef?.current?.querySelector('input')?.blur()
        }
    }, [isBackdropOpen, isSearchFormActive, isDesktop])

    // Remove active state when clicking outside
    useEffect(() => {
        const mouseDownHandler = (e: Event) => {
            if (!searchContainerRef?.current?.contains(e.target as HTMLElement) && isDesktop) {
                setIsSearchFormActive(false)
            }
        }

        if (searchContainerRef) {
            document.addEventListener('mousedown', mouseDownHandler)
        }

        return () => document.removeEventListener('mousedown', mouseDownHandler)
    }, [searchContainerRef, isDesktop])

    // GA4 e-commerce funnel tracking
    useEffect(() => {
        if (!fetching && query?.length > 0 && query != lastQuery) {
            trackSearchEvent(window.location.href)
            setLastQuery(query)
        }

        // Ensure we aren't sending too many e-commerce event while a user is searching
        if (!fetching && hasResults && !isEqual(productions, previousProductions)) {
            setPreviousProductions(productions)

            trackViewProductionList({ item_list_name: AnalyticsIds.SearchBar, productions })
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [fetching, query, productions, hasResults])

    useEffect(() => {
        const setAnimationTimer = () => {
            ;(placeholderTimeout as TimeoutRef).current = setInterval(() => {
                setPlaceholderIndex((index) => (index + 1) % placeholderItems.length)
            }, PLACEHOLDER_CHANGE_INTERVAL_MS) as Timeout
        }

        const clearAnimationTimer = () => {
            if (placeholderTimeout.current) {
                clearInterval(placeholderTimeout.current)
                ;(placeholderTimeout as TimeoutRef).current = null
            }
        }

        if (isAnimatedSearchbarPlaceholderEnabled) {
            setAnimationTimer()
        } else {
            clearAnimationTimer()
        }

        return () => clearAnimationTimer()
    }, [isAnimatedSearchbarPlaceholderEnabled])

    useEffect(() => {
        if (!shouldPersistSearchTerm) {
            removeSearchTermFromStorage()
            setQuery('')
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [pathname])

    useEffect(() => {
        if (isSearchPage && typeof routerQuery['searchTerm'] === 'string') {
            setSearchTerm(routerQuery['searchTerm'])
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isSearchPage])

    // Event handlers
    const onChangeHandler = (query: string) => {
        if (!isBackdropOpen) {
            trackSearchEvent(window.location.href)
            setToggled({ toggled: true, lockScroll })
        }

        setQuery(query)
    }

    const onFocusHandler = () => {
        trackSearchEvent(window.location.href)

        setToggled({ toggled: true, lockScroll })
        setIsSearchFormActive(true)
        handleSearchbarFocus?.(true)
    }

    const onBlurHandler = () => {
        handleSearchbarFocus?.(false)
    }

    const onKeydownHandler = (e: React.KeyboardEvent<Element>) => {
        if (e.key === 'Tab') {
            setIsSearchFormActive(false)
        }
    }

    const onCancelHandler = () => {
        if (!isDesktop && !(isMobile && isHome)) {
            setIsIconOnly(true)
        }

        setToggled({ toggled: false, lockScroll: false })
        setIsSearchFormActive(false)
    }

    const onResetHandler = () => {
        removeSearchTermFromStorage()
        setQuery('')
    }

    const onEnterHandler = () => {
        setSearchTermInStorage(query)
    }

    const onSearchIconClick = () => {
        setIsIconOnly(false)
        setIsSearchFormActive(true)
    }

    const onRouteChange = () => {
        if (!isDesktop && !(isMobile && isHome)) {
            setIsIconOnly(true)
        }

        setIsSearchFormActive(false)
        setToggled({ toggled: false, lockScroll })
    }

    const containerClassNames = classNames(styles.container, {
        [styles.isActive]: isSearchFormActive,
        [styles.isCenteredInHero]: isCenteredInHero,
        [styles.isFullWidth]: isFullWidth,
    })

    const suggestionContainerClassNames = classNames(styles.suggestionContainer, {
        [styles.isCenteredInHero]: isCenteredInHero,
        [styles.isFullWidth]: isFullWidth,
    })

    if (isIconOnly) {
        return (
            <Button data-testid="btn-search-icon-btn" importance="text" onClick={onSearchIconClick}>
                <Icon
                    data-testid="btn-search-icon"
                    className={classNames(styles.icon, {
                        [styles.headerTransition]: isTablet && isHome && isTransparent,
                        [styles[transparentHeaderClassName]]: isTablet && isHome && isTransparent,
                    })}
                    size="md"
                    type="search"
                />
            </Button>
        )
    }

    return (
        <div data-testid="btn-search-full" className={containerClassNames} ref={searchContainerRef}>
            <SearchForm
                placeholder={
                    shouldStartAnimatingPlaceholder
                        ? `"${placeholderItems[placeholderIndex]}"`
                        : 'Search by artist, team, or venue'
                }
                shouldShowAnimatedPlaceholder={shouldStartAnimatingPlaceholder}
                value={query}
                isActive={isSearchFormActive}
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                onKeyDownFocus={onKeydownHandler}
                onReset={onResetHandler}
                onCancel={onCancelHandler}
                onEnter={onEnterHandler}
                isCenteredInHero={isCenteredInHero}
                isFullWidth={isFullWidth}
                cursorPosition={cursorPosition}
                handleSetCursorPosition={handleSetCursorPosition}
                handleGoToSelectedItem={handleGoToSelectedItem}
            />
            {hasResults && !!query && isSearchFormActive ? (
                <div className={suggestionContainerClassNames}>
                    <SearchSuggestions
                        performers={performers}
                        productions={productionListRows}
                        venues={venues}
                        query={query}
                        cursorPosition={cursorPosition || 0}
                        isCenteredInHero={isCenteredInHero}
                    />
                </div>
            ) : null}
            {query === '' && isSearchFormActive ? (
                <div className={suggestionContainerClassNames} tabIndex={-1}>
                    <FocusSearchSuggestions />
                </div>
            ) : null}
        </div>
    )
}

export default withOptimizely(SearchBar)
