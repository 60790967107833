import { CATEGORY_IDS, CATEGORY_NAMES } from '@/constants'
import {
    queryParamToBool,
    queryParamToDateStr,
    queryParamToInt,
    queryParamToStr,
    queryParamToStrEnum,
} from '@/utils/query-strings'

import type {
    FilterMapper,
    HomeOrAwayType,
    HourStartEndType,
    PageFiltersType,
    ProductionListOnlyFilterParamNames,
    ProductionListOnlyFilterParams,
} from './types'

export const FILTERABLE_PAGES = [
    'categoryPage',
    'subCategoryPage',
    'performerPage',
    'regionPage',
    'venuePage',
    'searchPage',
] as const

export const HOME_OR_AWAY_OPTIONS = ['HOME', 'AWAY'] as const

export const HOUR_START_END_OPTIONS = ['0', '17'] as const

export const SORT_BY_OPTIONS = ['DATE', 'RANK', 'NAME'] as const

export const TAG_FILTER_OPTIONS = ['giveaway'] as const

export const QUERYPARAM = 'query'

export const CALENDAR_PARAM = 'calendar'

export const OPPONENTID_PARAM = 'opponentId'

// Ensure that all properties (including optional) are explicitly specified in the object
// export const ALL_RESET_PRODUCTION_LIST_PARAMS: {
// We never want PerformerPageNonFilterParams to reset, since they're not real filters
export const ALL_RESET_PRODUCTION_LIST_PARAMS: Record<
    ProductionListOnlyFilterParamNames,
    ProductionListOnlyFilterParams[ProductionListOnlyFilterParamNames]
> = {
    categoryId: undefined,
    subCategoryId: undefined,
    city: undefined,
    excludeParking: undefined,
    homeOrAway: undefined,
    hourStart: undefined,
    hourEnd: undefined,
    includeIpAddress: undefined,
    latLong: undefined,
    opponentId: undefined,
    page: undefined,
    pageSize: undefined,
    performerId: undefined,
    radius: undefined,
    regionId: undefined,
    sortBy: undefined,
    startDate: undefined,
    endDate: undefined,
    onsaleStartDate: undefined,
    onsaleEndDate: undefined,
    presaleStartDate: undefined,
    presaleEndDate: undefined,
    venueId: undefined,
    query: undefined,
    distinct: undefined,
    minListingPriceCeiling: undefined,
    minTicketCount: undefined,
    currency: undefined,
    tagFilter: undefined,
}

export const cityFilterMapper: FilterMapper<'city'> = {
    fromQuery: (query) => ({ city: queryParamToStr(query.city) }),
    toQuery: (city: string | undefined) => ({ city }),
}

export const homeOrAwayFilterMapper: FilterMapper<'homeOrAway'> = {
    fromQuery: (query) => ({ homeOrAway: queryParamToStrEnum(query.homeOrAway, [...HOME_OR_AWAY_OPTIONS]) }),
    toQuery: (homeOrAway: HomeOrAwayType | undefined) => ({ homeOrAway }),
}

export const opponentIdFilterMapper: FilterMapper<'opponentId'> = {
    fromQuery: (query) => ({ opponentId: queryParamToInt(query.opponentId) }),
    toQuery: (opponentId: number | undefined) => ({ opponentId: opponentId?.toString() }),
}

export const hourStartFilterMapper: FilterMapper<'hourStart'> = {
    fromQuery: (query) => ({ hourStart: queryParamToStrEnum(query.hourStart, [...HOUR_START_END_OPTIONS]) }),
    toQuery: (hourStart: HourStartEndType | undefined) => ({ hourStart }),
}

export const hourEndFilterMapper: FilterMapper<'hourEnd'> = {
    fromQuery: (query) => ({ hourEnd: queryParamToStrEnum(query.hourEnd, [...HOUR_START_END_OPTIONS]) }),
    toQuery: (hourEnd: HourStartEndType | undefined) => ({ hourEnd }),
}

export const startDateFilterMapper: FilterMapper<'startDate'> = {
    fromQuery: (query) => ({ startDate: queryParamToDateStr(query.startDate, 'YYYY-MM-DD') }),
    toQuery: (startDate: string | undefined) => ({ startDate }),
}

export const endDateFilterMapper: FilterMapper<'endDate'> = {
    fromQuery: (query) => ({ endDate: queryParamToDateStr(query.endDate, 'YYYY-MM-DD') }),
    toQuery: (endDate: string | undefined) => ({ endDate }),
}

export const categoryIdFilterMapper: FilterMapper<'categoryId'> = {
    fromQuery: (query) => {
        const categoryName = queryParamToStr(query.category)?.toUpperCase() as keyof typeof CATEGORY_IDS | undefined
        return { categoryId: categoryName && CATEGORY_IDS[categoryName] }
    },
    toQuery: (categoryId: CATEGORY_IDS | undefined) => ({
        category: categoryId ? CATEGORY_NAMES[categoryId] : undefined,
    }),
}

export const subCategoryIdFilterMapper: FilterMapper<'subCategoryId'> = {
    fromQuery: (query) => ({ subCategoryId: queryParamToStr(query.subCategoryId) }),
    toQuery: (subCategoryId: number | string | undefined) => ({ subCategoryId: subCategoryId?.toString() }),
}

export const performerIdFilterMapper: FilterMapper<'performerId'> = {
    fromQuery: (query) => ({ performerId: queryParamToInt(query.performerId) }),
    toQuery: (performerId: number | undefined) => ({ performerId: performerId?.toString() }),
}

export const queryFilterMapper: FilterMapper<'query'> = {
    fromQuery: (query) => ({ query: queryParamToStr(query.query) }),
    toQuery: (query: string | undefined) => ({ query }),
}

export const minListingPriceCeilingFilterMapper: FilterMapper<'minListingPriceCeiling'> = {
    fromQuery: (query) => ({ minListingPriceCeiling: queryParamToInt(query.minListingPriceCeiling) }),
    toQuery: (minListingPriceCeiling: number | undefined) => ({
        minListingPriceCeiling: minListingPriceCeiling?.toString(),
    }),
}

export const minTicketCountFilterMapper: FilterMapper<'minTicketCount'> = {
    fromQuery: (query) => ({ minTicketCount: queryParamToInt(query.minTicketCount) }),
    toQuery: (minTicketCount: number | undefined) => ({
        minTicketCount: minTicketCount?.toString(),
    }),
}

export const calendarMapper: FilterMapper<'calendar'> = {
    fromQuery: (query) => {
        if (query.calendar === undefined) {
            return { calendar: undefined }
        }

        return { calendar: queryParamToBool(query.calendar) }
    },
    toQuery: (calendar: boolean | undefined) => {
        if (calendar === undefined) {
            return {}
        }

        return { calendar: String(calendar) }
    },
}

export const tagFilterMapper: FilterMapper<'tagFilter'> = {
    fromQuery: (query) => ({ tagFilter: queryParamToStrEnum(query.tagFilter, ['giveaway']) }),
    toQuery: (tagFilter: string | undefined) => ({ tagFilter }),
}

/** For each filterable page define all supported filters with mappers from/to query parameter */
export const PAGE_FILTERS: PageFiltersType = {
    categoryPage: {
        startDate: startDateFilterMapper,
        endDate: endDateFilterMapper,
        subCategoryId: subCategoryIdFilterMapper,
    },
    subCategoryPage: {
        startDate: startDateFilterMapper,
        endDate: endDateFilterMapper,
        city: cityFilterMapper,
        minListingPriceCeiling: minListingPriceCeilingFilterMapper,
        minTicketCount: minTicketCountFilterMapper,
    },
    performerPage: {
        city: cityFilterMapper,
        homeOrAway: homeOrAwayFilterMapper,
        opponentId: opponentIdFilterMapper,
        hourStart: hourStartFilterMapper,
        hourEnd: hourEndFilterMapper,
        startDate: startDateFilterMapper,
        endDate: endDateFilterMapper,
        minListingPriceCeiling: minListingPriceCeilingFilterMapper,
        minTicketCount: minTicketCountFilterMapper,
        calendar: calendarMapper,
        tagFilter: tagFilterMapper,
    },
    regionPage: {
        hourStart: hourStartFilterMapper,
        hourEnd: hourEndFilterMapper,
        startDate: startDateFilterMapper,
        endDate: endDateFilterMapper,
    },
    venuePage: {
        categoryId: categoryIdFilterMapper,
        performerId: performerIdFilterMapper,
        hourStart: hourStartFilterMapper,
        hourEnd: hourEndFilterMapper,
        startDate: startDateFilterMapper,
        endDate: endDateFilterMapper,
    },
    searchPage: {
        hourStart: hourStartFilterMapper,
        hourEnd: hourEndFilterMapper,
        startDate: startDateFilterMapper,
        endDate: endDateFilterMapper,
        query: queryFilterMapper,
    },
}
