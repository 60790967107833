import type { $FC } from 'react'
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'

import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'

import { serverSideQuery, useQuery } from '@/api'
import { PRODUCTIONS_ENDPOINT, PRODUCTIONS_PAGE_SIZE } from '@/constants'
import { useLocalizedCurrencyFeature } from '@/optimizely/features/internationalization/hooks/localized-currency'

import InternationalizationContext from '../internationalization'
import { DEFAULT_CURRENCY } from '../internationalization/constants'

import { ALL_RESET_PRODUCTION_LIST_PARAMS, QUERYPARAM } from './constants'
import {
    composedProductionsSelector,
    filteredProductionListTitleSelector,
    filterParamsSelector,
    hasScheduleContentTagsSelector,
    hasFiltersAppliedSelector,
    hasFiltersAppliedMatchupPageSelector,
    hasMoreProductionsSelector,
    hiddenByDefaultProductionsSelector,
    jsonLdProductionsSelector,
    lastFetchedPageSelector,
    performerIdsSelector,
    productionsSelector,
    relatedProductionsListSelector,
    remainingProductionCountSelector,
    sortedByTicketQuantityProductionSelector,
    visibleByDefaultProductionsSelector,
    hasGiveawayContentTagsSelector,
    regionIdSelector,
    productionsWithUniquePerformersSelector,
} from './selectors'
import type {
    FilterMapper,
    ProductionListContextType,
    ProductionListData,
    ProductionListDispatch,
    ProductionListParamKey,
    ProductionListParams,
    ProductionListParamValue,
    ProductionListProviderProps,
    ProductionListSelectors,
    ProductionListState,
} from './types'
import { mergeProductionListData, updateUrlParameters } from './utils'

export const defaultContextValue: ProductionListContextType = {
    state: {},
    selectors: {
        productions: [],
        composedProductions: [],
        jsonLdProductions: [],
        lastFetchedPage: 0,
        remainingProductionCount: 0,
        hasMoreProductions: false,
        visibleByDefaultProductions: [],
        hiddenByDefaultProductions: [],
        params: {},
        filterMappers: {},
        filterParams: {},
        hasFiltersApplied: false,
        hasFiltersAppliedMatchupPage: false,
        filteredProductionListTitle: '',
        relatedProductionsList: [],
        performerIds: [],
        analyticsSubIds: [],
        sortedVolumeProductions: [],
        hasScheduleContentTags: false,
        hasGiveawayContentTags: false,
        regionId: undefined,
        productionsWithUniquePerformers: [],
    },
    dispatch: {
        fetchNextPage: () => undefined,
        onFilterChangeAction: () => undefined,
        onFilterResetAction: () => undefined,
        overrideFilterParams: () => undefined,
        updateParams: () => undefined,
    },
}

const ProductionListContext = createContext<ProductionListContextType>(defaultContextValue)
const { Provider } = ProductionListContext

const defaultParams: ProductionListParams = {
    page: 1,
    pageSize: PRODUCTIONS_PAGE_SIZE,
}

export const ProductionListProvider: $FC<ProductionListProviderProps> = ({
    children,
    initialParams,
    initialProps,
    enabled = true,
    filterMappers,
    analyticsSubIds = [],
    updateUrlOnFilterChange = true,
}) => {
    const {
        selectors: { selectedCurrency },
    } = useContext(InternationalizationContext)
    const [isLocalizedCurrencyEnabled] = useLocalizedCurrencyFeature()

    const currency = isLocalizedCurrencyEnabled ? selectedCurrency : DEFAULT_CURRENCY

    // Accumulated productions for all previously fetched pages
    const accumProductionListDataRef = useRef<ProductionListData | undefined>(undefined)

    // State for params
    const prevInitialParamsRef = useRef<ProductionListParams | undefined>(initialParams)
    // By default set page number to 1 and page size to 100
    const [params, setParams] = useState<ProductionListParams>({ ...defaultParams, ...initialParams })

    useEffect(() => {
        if (prevInitialParamsRef.current !== initialParams) {
            accumProductionListDataRef.current = initialProps?.data
            setParams({ ...defaultParams, ...initialParams })
            prevInitialParamsRef.current = initialParams
        }
    }, [initialParams, initialProps])

    const {
        fetching,
        data: newProductionListData,
        error,
    } = useQuery<ProductionListData>({
        endpoint: PRODUCTIONS_ENDPOINT,
        params: { ...params, currency },
        initialData: initialProps?.data,
        enabled,
    })

    const filterStatePersistent = useRef<ProductionListParams>(pick(params, Object.keys(filterMappers)))

    // Build the final context value
    // This is to minimize the number of re-computations, i.e. re-compute only when direct dependencies get updated
    const contextValue: ProductionListContextType = useMemo(() => {
        if (!fetching) {
            const data = mergeProductionListData(accumProductionListDataRef.current, newProductionListData)

            accumProductionListDataRef.current = data
        }

        const state: ProductionListState = { fetching, data: accumProductionListDataRef.current, error }

        const selectors: ProductionListSelectors = {
            productions: productionsSelector(state),
            composedProductions: composedProductionsSelector(state),
            jsonLdProductions: jsonLdProductionsSelector(state),
            lastFetchedPage: lastFetchedPageSelector(state),
            hasMoreProductions: hasMoreProductionsSelector(state),
            remainingProductionCount: remainingProductionCountSelector(state),
            visibleByDefaultProductions: visibleByDefaultProductionsSelector(state),
            hiddenByDefaultProductions: hiddenByDefaultProductionsSelector(state),
            params,
            filterMappers,
            filterParams: filterParamsSelector([params, filterMappers]),
            hasFiltersApplied: hasFiltersAppliedSelector([params, filterMappers]),
            hasFiltersAppliedMatchupPage: hasFiltersAppliedMatchupPageSelector([params, filterMappers]),
            filteredProductionListTitle: filteredProductionListTitleSelector(state),
            relatedProductionsList: relatedProductionsListSelector(state),
            performerIds: performerIdsSelector(state),
            sortedVolumeProductions: sortedByTicketQuantityProductionSelector(state),
            analyticsSubIds,
            hasScheduleContentTags: hasScheduleContentTagsSelector(state),
            hasGiveawayContentTags: hasGiveawayContentTagsSelector(state),
            regionId: regionIdSelector(state),
            productionsWithUniquePerformers: productionsWithUniquePerformersSelector(state),
        }

        const nextPage: number = selectors.lastFetchedPage + 1
        const fetchNextPage = (): void => {
            setParams((prevParams: ProductionListParams) => {
                return prevParams.page !== nextPage ? { ...prevParams, page: nextPage } : prevParams
            })
        }

        const onFilterChangeAction = (newParams: Partial<ProductionListParams>): void => {
            setParams((prevParams: ProductionListParams) => {
                let updatedParams: ProductionListParams = prevParams
                let paramKey: ProductionListParamKey

                for (paramKey in newParams) {
                    // Process only supported filter parameters
                    const filterMapper = filterMappers[paramKey] as FilterMapper<typeof paramKey> | undefined
                    if (filterMapper) {
                        const newParamValue: ProductionListParamValue = newParams[paramKey]
                        // update all params except 'query' (for search)
                        if (paramKey !== QUERYPARAM) {
                            if (updateUrlOnFilterChange) {
                                const { toQuery } = filterMapper
                                filterStatePersistent.current = {
                                    ...filterStatePersistent.current,
                                    ...toQuery(newParamValue),
                                }
                            }
                            updatedParams = { ...updatedParams, [paramKey]: newParamValue }
                        }
                    } else {
                        console.error(`[${paramKey}] parameter is not filterable`)
                    }
                }

                if (updatedParams !== prevParams) {
                    if (updateUrlOnFilterChange) {
                        updateUrlParameters(filterStatePersistent.current)
                    }
                    // Need to reset page to 1 whenever filter parameters change
                    updatedParams = { ...updatedParams, page: 1 }
                }

                return updatedParams
            })
        }

        const onFilterResetAction = (excludeKeys?: string[]): void => {
            // Reset only supported filter parameters
            const filterParamKeys: string[] = Object.keys(filterMappers)
            const resetParams: Partial<ProductionListParams> = pickBy(ALL_RESET_PRODUCTION_LIST_PARAMS, (_, paramKey) =>
                filterParamKeys.filter((key) => !excludeKeys?.includes(key)).includes(paramKey),
            )
            onFilterChangeAction(resetParams)
        }

        const overrideFilterParams = (newParams: Partial<ProductionListParams>) => {
            setParams(newParams)
        }

        const updateParams = (newParams: Partial<ProductionListParams>) => {
            setParams({ ...params, ...newParams })
        }

        const dispatch: ProductionListDispatch = {
            fetchNextPage,
            onFilterChangeAction,
            onFilterResetAction,
            overrideFilterParams,
            updateParams,
        }

        return { state, selectors, dispatch }
    }, [fetching, newProductionListData, error, filterMappers, updateUrlOnFilterChange, params, analyticsSubIds])

    return <Provider value={contextValue}>{children}</Provider>
}

export const serverSideRead = async (params: ProductionListParams, maxAgeMs?: number): Promise<ProductionListData> => {
    // By default set page number to 1 and page size to 100
    const paramsWithDefaults: ProductionListParams = { ...defaultParams, ...params }
    const queryInstance = serverSideQuery<ProductionListData>(PRODUCTIONS_ENDPOINT)
    const productionListData: ProductionListData = await queryInstance(paramsWithDefaults, maxAgeMs)
    return productionListData
}

export default ProductionListContext
