import { CATEGORY_IDS } from '@/constants'

export const RELATED_ARTISTS_ENABLED = {
    [CATEGORY_IDS.SPORTS]: false,
    [CATEGORY_IDS.CONCERTS]: true,
    [CATEGORY_IDS.THEATER]: true,
}

export const FEATURED_PRODUCTIONS_ENABLED = {
    [CATEGORY_IDS.SPORTS]: false,
    [CATEGORY_IDS.CONCERTS]: true,
    [CATEGORY_IDS.THEATER]: true,
}

export enum GEOLOCATION_PERFORMER_IDS {
    GOLD_OVER_AMERICA = 131092,
    MONSTER_JAM = 2186,
    NASCAR_CUP_SERIES = 3835,
    PRO_BULL_RIDING = 1162,
    WWE_RAW = 26077,
}

// Redirect certain Travel Package paths to legacy
// See https://vividseats.atlassian.net/browse/BW-1657
export const REDIRECT_MAP = new Map<string, string>([
    [
        '/cleveland-browns-official-fan-package-tickets--sports-nfl-football/performer/53045',
        '/nfl/cleveland-browns-official-fan-packages.html',
    ],
    [
        '/indianapolis-colts-official-fan-experience-package-tickets--sports-nfl-football/performer/114836',
        '/nfl/indianapolis-colts-official-fan-experience-packages.html',
    ],
    [
        '/san-francisco-49ers-official-fan-travel-package-tickets--sports-nfl-football/performer/63459',
        '/nfl/san-francisco-49ers-official-fan-packages.html',
    ],
])

export const INIT_USER_LOCATION_COOKIE = { latLong: undefined, hasSelectedLocation: undefined }
