import { createSelector } from 'reselect'

import { IMAGES } from '@/constants'
import { normalizeSecureImages } from '@/context/utils/image-path'
import type { CatalogAssetRelatedResource, CatalogImageAsset, ImageSelectorType } from '@/types/app-types'

import type { CatalogAssetStateType, ImageSelectorByPerformerId } from './types'
import { getResponsiveImages } from './utils'

/**
 * Returns an object {@link ImageSelectorByPerformerId} where each property is the performer id
 * and its value are the suitable images {@link getImages}
 *
 * @return Object of type {@link ImageSelectorByPerformerId}
 */
export const getImagesByPerformer = createSelector(
    (state: CatalogAssetStateType) => state,
    ({ data, fetching }: CatalogAssetStateType): ImageSelectorByPerformerId => {
        const performerIdImages: { [key: number]: CatalogImageAsset[] } =
            data?.items?.reduce((acc, performer) => {
                // first select the primary images
                let images: CatalogImageAsset[] | CatalogAssetRelatedResource[] | undefined = performer?.images

                if (!images || images.length < 1) {
                    // if those images do not exist then select the sub cateogry images
                    images = performer?.relatedResources?.[0]?.images

                    if (!images || images.length < 1) {
                        // otherwise select the main cateogery images
                        images = performer?.relatedResources?.[1]?.images
                    }
                }

                return { ...acc, [performer.id]: images }
            }, {}) || {}

        return (
            data?.items?.reduce((acc, performer) => {
                const images = getImages(performerIdImages[performer.id], fetching, true, performer.relatedResources)
                return { ...acc, [performer.id]: images }
            }, {}) || {}
        )
    },
)

export const imagesSelector = createSelector(
    (state: CatalogAssetStateType) => state,
    ({ data, fetching }): ImageSelectorType => {
        const catalogState = data?.items?.[0]

        // first select the primary images
        let images: CatalogImageAsset[] | CatalogAssetRelatedResource[] | undefined = catalogState?.images

        if (!images || images.length < 1) {
            // if those images do not exist then select the sub cateogry images
            images = catalogState?.relatedResources?.[0]?.images

            if (!images || images.length < 1) {
                // otherwise select the main cateogery images
                images = catalogState?.relatedResources?.[1]?.images
            }
        }

        return getImages(images || [], fetching)
    },
)

export const getImages = (
    images: CatalogImageAsset[],
    fetching: boolean | undefined,
    shouldAutoReplace?: boolean,
    relatedResources?: CatalogAssetRelatedResource[],
): ImageSelectorType => {
    const externalImage = images?.find((image) => image.dataModelName === 'EXTERNAL')
    const mobileImage = images?.find((image) => image.size === 'MOBILEHERO')
    const desktopImage = images?.find((image) => image.size === 'HERO')
    const thumbImage = images?.find((image) => image.size === 'THUMBNAIL')
    const subCategory = relatedResources?.find((relatedResources) => relatedResources.type === 'SUBCATEGORY')
    const subCategoryImage = subCategory?.images?.find((image) => image.dataModelName === 'EXTERNAL')
    const category = relatedResources?.find((relatedResources) => relatedResources.type === 'CATEGORY')
    const categoryImage = category?.images?.find((image) => image.dataModelName === 'EXTERNAL')
    const imagePaths = {
        external: normalizeSecureImages(externalImage?.path),
        mobile: normalizeSecureImages(mobileImage?.path),
        desktop: normalizeSecureImages(desktopImage?.path),
        thumbnail: normalizeSecureImages(thumbImage?.path),
        fallbackUrl: normalizeSecureImages(subCategoryImage?.path || categoryImage?.path),
    }

    if (!fetching && shouldAutoReplace !== false) {
        if (imagePaths.desktop && !imagePaths.mobile) imagePaths.mobile = imagePaths.desktop
        if (!imagePaths.desktop && !imagePaths.fallbackUrl) imagePaths.desktop = IMAGES.DEFAULT_HERO_DESKTOP
        if (!imagePaths.mobile && !imagePaths.fallbackUrl) imagePaths.mobile = IMAGES.REGION_HERO_MOBILE
    }

    return imagePaths
}

export const responsiveImageSelector = createSelector(
    [
        ({ defaultImages }: { defaultImages: ImageSelectorType; overrideImageUrl?: string }) => defaultImages,
        ({ overrideImageUrl }: { defaultImages: ImageSelectorType; overrideImageUrl?: string }) => {
            return overrideImageUrl
        },
    ],
    getResponsiveImages,
)
