import pickBy from 'lodash/pickBy'
import type { ParsedUrlQuery } from 'querystring'

import type { ProductionListSelectorType, TableCell, TableDef } from '@/types/app-types'

import type {
    FilterMappers,
    ProductionListData,
    ProductionListParams,
    ProductionListParamValue,
    ProductionToursColumn,
} from './types'

/** Function to append newly fetched productions to the current state */
export const mergeProductionListData = (
    prevProductionListData?: ProductionListData,
    newProductionListData?: ProductionListData,
): ProductionListData | undefined => {
    if (!newProductionListData) {
        return prevProductionListData
    }

    if (!prevProductionListData || newProductionListData.page === 1) {
        return newProductionListData
    }

    // Do not merge productions for pages that have already been fetched
    if (prevProductionListData.page >= newProductionListData.page) {
        return prevProductionListData
    }

    // If page number is 2 or more then preserve the existing (i.e. previously fetched) productions
    // The assumption is that pagination is done sequentially, e.g. page 1, 2, 3, etc.
    return {
        ...newProductionListData,
        items: [...prevProductionListData.items, ...newProductionListData.items],
    }
}

export const getProductionToursTableDef = (
    productions: ProductionListSelectorType[],
    cellClassNames?: Partial<Record<ProductionToursColumn, string>>,
): TableDef => {
    const headers: Array<TableCell> = [
        { value: 'DATE' },
        { value: 'CITY' },
        { value: 'VENUE' },
        { value: 'LOWEST PRICE' },
    ]

    const rows: Array<Array<TableCell>> = productions.map((production: ProductionListSelectorType) => {
        const { shortLocalDate, location, venueName, venueOrganicUrl, minPrice: price = 0 } = production
        const rowCells: Array<TableCell> = [
            { value: shortLocalDate, className: cellClassNames?.date },
            { value: location, className: cellClassNames?.city },
            { value: venueName, href: venueOrganicUrl, className: cellClassNames?.venue },
            { value: price > 0 ? `$${price}` : '', className: cellClassNames?.lowestPrice },
        ]
        return rowCells
    })

    const productionToursTableDef: TableDef = { headers, rows }
    return productionToursTableDef
}

export const getQueryParamString = (queryParams?: ProductionListParams | object) => {
    if (!queryParams) return ''
    const paramString = new URLSearchParams(queryParams as Record<string, string>).toString()
    return paramString && `?${paramString}`
}

export const updateUrlParameters = (filtersState: ProductionListParams | object) => {
    // pickBy will exclude all falsy values, e.g. undefined, empty strings
    const newQueryParams = pickBy(filtersState, (value) => !!value)

    // INP improvement: replaced use of router.replace()
    window.history.replaceState(
        window.history.state,
        '',
        `${location.pathname}${getQueryParamString(newQueryParams)}${location.hash}`,
    )
}

export const extractSupportedUrlParameters = (
    query: ParsedUrlQuery,
    filterMappers: FilterMappers,
): Partial<ProductionListParams> => {
    // Extract only supported filter parameters
    let productionListParams: Partial<ProductionListParams> = {}
    Object.values(filterMappers).forEach(({ fromQuery }) => {
        productionListParams = { ...productionListParams, ...fromQuery(query) }
    })
    // pickBy will exclude all falsy values, e.g. undefined, empty strings, 0s
    return pickBy(productionListParams, (paramValue: ProductionListParamValue) => isValuePresent(paramValue))
}

/** Returns false for all falsy values, e.g. undefined, empty strings, 0s */
export const isValuePresent = (paramValue: ProductionListParamValue): boolean => {
    // Boolean's false is treated as present
    return typeof paramValue === 'boolean' || ![null, undefined, '', 0].includes(paramValue)
}
