import type { $FC } from 'react'
import React, { createContext, useContext } from 'react'

import { serverSideQuery, useQuery } from '@/api'
import { PRODUCTIONS_ENDPOINT } from '@/constants'
import { useLocalizedCurrencyFeature } from '@/optimizely/features/internationalization/hooks/localized-currency'
import type { ProductionDetail } from '@/types/app-types'

import InternationalizationContext from '../internationalization/context'

import {
    dateSwitcherInfoSelector,
    dateSwitcherSheetInfoSelector,
    eventInfoSelector,
    eventLineupSelector,
    heroDataSelector,
    jsonLdDataSelector,
    productionVenueInfoSelector,
} from './selectors'
import type {
    ProductionDetailsContextType,
    ProductionDetailsParams,
    ProductionDetailsProviderProps,
    ProductionDetailsSelectors,
    ProductionDetailsState,
} from './types'

const defaultContextValue: ProductionDetailsContextType = {
    state: {},
    selectors: {
        productionDetails: undefined,
        jsonLdData: { '@context': 'http://schema.org' },
        heroData: undefined,
        eventInfo: undefined,
        eventLineup: undefined,
        productionVenueInfo: undefined,
        dateSwitcherInfo: undefined,
        dateSwitcherSheetInfo: undefined,
    },
}

const ProductionDetailsContext = createContext<ProductionDetailsContextType>(defaultContextValue)
const { Provider } = ProductionDetailsContext

const getProductionDetailsEndpoint = (productionId: number): string => {
    return `${PRODUCTIONS_ENDPOINT}/${productionId}/details`
}

export const ProductionDetailsProvider: $FC<ProductionDetailsProviderProps> = ({
    children,
    initialParams,
    initialProps,
}) => {
    const [isLocalizedCurrencyEnabled] = useLocalizedCurrencyFeature()

    const {
        selectors: { selectedCurrency },
    } = useContext(InternationalizationContext)

    const { productionId, ...queryParams } = initialParams
    const productionDetailsEndpoint: string = getProductionDetailsEndpoint(productionId)

    const currency = isLocalizedCurrencyEnabled ? selectedCurrency : ''

    const { fetching, data, error } = useQuery<ProductionDetail>({
        endpoint: productionDetailsEndpoint,
        params: { ...queryParams, currency },
        initialData: initialProps?.data,
        enabled: !!productionId,
    })

    const state: ProductionDetailsState = { fetching, data, error }

    const selectors: ProductionDetailsSelectors = {
        productionDetails: data,
        jsonLdData: jsonLdDataSelector(state),
        heroData: heroDataSelector(state),
        eventInfo: eventInfoSelector(state),
        eventLineup: eventLineupSelector(state),
        productionVenueInfo: productionVenueInfoSelector(state),
        dateSwitcherInfo: dateSwitcherInfoSelector(state),
        dateSwitcherSheetInfo: dateSwitcherSheetInfoSelector(state, currency),
    }

    return <Provider value={{ state, selectors }}>{children}</Provider>
}

export const serverSideRead = async (params: ProductionDetailsParams, maxAgeMs?: number): Promise<ProductionDetail> => {
    const { productionId, ...queryParams } = params
    const productionDetailsEndpoint: string = getProductionDetailsEndpoint(productionId)
    const queryInstance = serverSideQuery<ProductionDetail>(productionDetailsEndpoint)
    const productionDetails: ProductionDetail = await queryInstance(queryParams, maxAgeMs)
    return productionDetails
}

export default ProductionDetailsContext
